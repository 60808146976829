import axios from 'axios';
export const checkoutNextCart = async (token) => {
    return axios.patch('/api/v2/storefront/checkout/next', {
        order_token: token
    }).then(response => {
        return response;
    });
};
export const checkoutCart = async (data) => {
    return axios.patch('/api/v2/storefront/checkout', {
        order: {
            ...data
        }
    }).then(response => {
        return response;
    });
};
export const setQuantity = async (id, quantity) => {
    return axios.patch('/api/v2/storefront/cart/set_quantity', {
        line_item_id: id,
        quantity
    }).then(response => {
        return response;
    });
};
export const removeLineItem = async (productId, token) => {
    return axios.delete("/api/v2/storefront/cart/remove_line_item/" + productId + "?order_token=" + token).then(response => {
        return response;
    });
};
export const emptyCart = async (token) => {
    return axios.patch('/api/v2/storefront/cart/empty', {
        params: {
            order_token: token
        }
    }).then(response => {
        return response;
    });
};
export const removeLineItems = async (itemsSelected, token) => {
    return axios.delete("/api/v2/storefront/cart/remove_line_items/", {
        params: {
            line_item_ids: itemsSelected,
            order_token: token
        }
    }).then(response => {
        return response;
    });
};
export const replaceLineItem = async (data) => {
    return axios.post("/api/v2/storefront/cart/replace_line_item/", data).then(response => {
        return response;
    });
};
export const getLineItems = async () => {
    return axios.get('/api/v2/storefront/cart', {
        params: {
            include: 'line_items'
        }
    }).then(response => {
        return response;
    });
};
export const getCart = async () => {
    return axios.get('/api/v2/storefront/cart').then(response => {
        return response;
    });
};
export const createCart = async () => {
    return axios.post('/api/v2/storefront/cart').then(response => {
        return response;
    });
};
export const addItemToCart = async (data) => {
    return axios.post('/api/v2/storefront/cart/add_item', {
        ...data
    }).then(response => {
        return response;
    });
};
export const setPriceType = async (data) => {
    return axios.post('/api/v2/storefront/cart/set_price_type', {
        ...data
    }).then(response => {
        return response;
    });
};
export const getDeliveryTimesWithCharges = async (cartId) => {
    return axios.get('/api/v2/storefront/cart/delivery_times_with_charges', {
        params: {
            order_id: cartId
        }
    }).then(response => {
        return response;
    });
};
export const setDeliveryTimeWindows = async (data) => {
    return axios.post('/api/v2/storefront/cart/set_delivery_time_window', {
        delivery_date: data.delivery_date,
        delivery_time_window: data.delivery_time_window,
        order_id: data.cartId
    }).then(response => {
        return response;
    });
};
export const setPurchaseOrderNumber = async (purchaseOrderNumber) => {
    return axios.post('/api/v2/storefront/cart/set_purchase_order_number', {}, {
        params: {
            purchase_order_number: purchaseOrderNumber
        }
    }).then(response => {
        return response;
    });
};
export async function getDocFees() {
    return axios.get('/api/v2/storefront/cart/doc_fees').then(function (response) {
        return response;
    }).catch(function (error) {
        return error === null || error === void 0 ? void 0 : error.response;
    });
}
